<template>
  <div class="bodys">
    <div class="cont">
      <div class="cont-right">
        <div class="cont-center">
          <div class="cont_top" @click="$router.replace('/vipCenter/profile')">
            <img src="../../../assets/img/pro_fan.png" alt="" />
          </div>
          <div class="code1">
            <div class="code_name">旧手机验证码</div>
            <input
              type="text"
              placeholder="请输入验证码"
              v-model="code1Value"
              class="inp_code1"
            />
            <button
              type="info"
              v-show="show2"
              @click="getCode2"
              class="get_code"
            >
              获取验证码
            </button>
            <button type="info" v-show="!show2" class="get_code">
              重新发送({{ count2 }}s)
            </button>
          </div>
          <div class="code1">
            <div class="code_name">新手机号</div>
            <input
              type="text"
              placeholder="请输入新手机号"
              maxlength="11"
              v-model="newPhoneValue"
              class="inp_code1"
            />
          </div>
          <div class="code1">
            <div class="code_name">新手机验证码</div>
            <input
              type="text"
              placeholder="请输入验证码"
              v-model="code2Value"
              class="inp_code1"
            />
            <button type="info" v-show="show" @click="getCode" class="get_code">
              获取验证码
            </button>
            <button type="info" v-show="!show" class="get_code">
              重新发送({{ count }}s)
            </button>
          </div>
          <div class="code2">
            <div class="code_name">登录密码</div>
            <input
              type="password"
              placeholder="请输入登录密码"
              v-model="pwdValue"
              class="inp_code1"
            />
          </div>
          <div class="code3">
            <div class="code_name"></div>
            <div class="inp_tishi">
              提示：如果旧手机号无法接收验证码，请联系客服修改手机号。
            </div>
          </div>
          <div class="code3">
            <div class="code_name"></div>
            <button class="inp_btn" @click="onSubmit">确定</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  changePhone,
  getVerifyCodeByPhone,
  getVerifyCode,
} from "@network/setting";
export default {
  name: "Personal",
  data() {
    return {
      code1Value: "", //旧手机验证码
      code2Value: "", //新手机验证码
      newPhoneValue: "", //新手机号
      pwdValue: "", //登录密码
      show: true, //新手机获取验证码
      show2: true, //旧手机获取验证码
      count: "", //新手机获取验证码
      count2: "", //旧手机获取验证码
      timer: null, //新手机获取验证码
      timer2: null, //旧手机获取验证码
      reg: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      oldExp: "",
      oldVerify: "",
      exp: "",
      verify: "",
    };
  },
  created() {},
  components: {},
  methods: {
    onSubmit() {
      if (
        this.code1Value != "" &&
        this.newPhoneValue != "" &&
        this.code2Value != "" &&
        this.pwdValue != ""
      ) {
        changePhone(
          this.oldExp,
          this.code1Value,
          this.oldVerify,
          this.newPhoneValue,
          this.exp,
          this.code2Value,
          this.verify,
          this.pwdValue
        ).then((res) => {
          console.log(res);
          this.$message({
            message: res.msg,
            offset: 300,
          });

          if (res.code != 0) {
            return false;
          } else {
            this.$storage.remove("token");
            this.$router.replace("/login");
          }
        });
      } else {
        this.$message.error({
          message: "请填写内容",
          // message: '这是一条带有偏移的提示消息',
          offset: 300,
        });
      }
    },
    // 新手机获取验证码
    getCode() {
      if (this.newPhoneValue == "") {
        this.$message.error({
          message: "请输入新手机号",
          offset: 300,
        });
        return false;
      }
      if (!this.reg.test(this.newPhoneValue)) {
        this.$message.error({
          message: "请输入正确的手机号",
          offset: 300,
        });

        return false;
      }
      const TIME_COUNT = 180;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        getVerifyCodeByPhone(this.newPhoneValue, "newPhone").then((res) => {
          console.log(res);
          this.$message({
            message: res.msg,
            // message: '这是一条带有偏移的提示消息',
            offset: 300,
          });
          if (res.code != 0) {
            return false;
          } else {
            this.exp = res.data.exp;
            this.verify = res.data.verify;
          }
        });
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 旧手机获取验证码
    getCode2() {
      const TIME_COUNT2 = 180;
      if (!this.timer2) {
        this.count2 = TIME_COUNT2;
        this.show2 = false;
        getVerifyCode("oldPhone").then((res) => {
          console.log(res);
          this.$message({
            message: res.msg,
            offset: 300,
          });
          if (res.code != 0) {
            return false;
          } else {
            this.oldExp = res.data.exp;
            this.oldVerify = res.data.verify;
          }
        });
        this.timer2 = setInterval(() => {
          if (this.count2 > 0 && this.count2 <= TIME_COUNT2) {
            this.count2--;
          } else {
            this.show2 = true;
            clearInterval(this.timer2);
            this.timer2 = null;
          }
        }, 1000);
      }
    },
  },
};
</script>
<style lang='less' scoped>
@import "../../../assets/css/vipCenter/ChildComps/changePhone.less";
</style>
